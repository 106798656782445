import * as React from 'react';

import ImpressionTracker from '../analytics/impression-tracker/ImpressionTracker';
import {Input} from '../ui/forms/Input/Input';
import {OrdersService} from '../../client/orders/orders.service';
import {PromoItem} from './promo.class';
import {Radio} from '../ui/forms/Radio/Radio';
import {useService} from '../react/ServiceContext';

interface PromoCardProps {
    onSelect: (itemNum: string) => void;
    onSelectEmail: (email: string) => void;
    onSelectTier: (index: string) => void;
    promoItem: PromoItem;
    promoItemIndex: number;
    promoTierOptionIndex: number;
    selectedPromoEmail: string;
    selection: string;
}

export const PromoCard = ({
    onSelect,
    onSelectEmail,
    onSelectTier,
    promoItem,
    promoItemIndex,
    promoTierOptionIndex,
    selectedPromoEmail,
    selection,
}: PromoCardProps) => {
    const componentName = `PromoCard`;
    const ordersService: OrdersService = useService(`ordersService`);

    /**
     * Template
     */
    return (
        <div className="col mb-3">
            <div className="card h-100 mb-2">
                <div className="card-body">
                    <Radio
                        alias={`promoItemOption${promoTierOptionIndex}-${promoItemIndex}`}
                        checked={promoItem.selected || selection === promoItem.item}
                        disabled={!promoItem.qualify}
                        label="Selected Item"
                        name="selected-item"
                        onChange={() => {
                            onSelect(promoItem.item);
                            onSelectTier(`${promoTierOptionIndex + 1}`);
                        }}
                        type="radio"
                        value={promoItem.item}
                    />
                    <div className="description mb-3">
                        {promoItem.limitReached && <span className="badge badge-light ml-3">ALL REDEEMED</span>}
                        {promoItem.itemType === `gift` && (
                            <>
                                <div className="mb-3 pt-2">{promoItem.h1HTML || promoItem.itemDesc}</div>
                                <div className={`mb-3 ${selection !== promoItem.item ? 'd-none' : ''}`}>
                                    <Input
                                        id={`promoItemEmail${promoTierOptionIndex}-${promoItemIndex}`}
                                        label="Email of Gift Card Recipient"
                                        name="promo-email"
                                        onChangeString={(thisValue) => {
                                            onSelectEmail(thisValue);
                                        }}
                                        type="text"
                                        autocorrect="off"
                                        value={promoItem.emailAddress || selectedPromoEmail}
                                    />
                                </div>
                            </>
                        )}
                        {promoItem.itemType === `item` && (
                            <ImpressionTracker
                                as="span"
                                data={{
                                    dimension16: ordersService?.currentOrderNumber,
                                    index: promoTierOptionIndex,
                                    item_id: promoItem.item,
                                    item_list_name: componentName,
                                }}
                            >
                                <a href={`/item/${promoItem.item}`}>{promoItem.h1HTML || promoItem.itemDesc}</a>
                            </ImpressionTracker>
                        )}
                    </div>
                    <img
                        alt={promoItem.itemDesc}
                        height="120"
                        src={
                            promoItem.imagePath
                                ? `/ProductImageThumbs120${promoItem.imagePath || 'noimage.png'}`
                                : `/thumb/120/${promoItem.image || 'noimage.png'}`
                        }
                        width="120"
                    />
                </div>
            </div>
        </div>
    );
};
